import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4a2604bd = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _2952519d = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _8e8c4936 = () => interopDefault(import('../pages/CareerOpportunities.vue' /* webpackChunkName: "" */))
const _8aa3f06e = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _3e2bbb14 = () => interopDefault(import('../pages/Catalog-Request-Form.vue' /* webpackChunkName: "" */))
const _2ce7379c = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "" */))
const _67de5092 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _dac416cc = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "" */))
const _5fcc2d28 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _0811b395 = () => interopDefault(import('../pages/Privacy-Policy.vue' /* webpackChunkName: "" */))
const _19baca3c = () => interopDefault(import('../pages/Terms-Conditions.vue' /* webpackChunkName: "" */))
const _42a3e66f = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _5f9990bb = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _036e3a74 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _595a85c4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _863eee76 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _156cfaa2 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _54a05c4a = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _08dd3c00 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _74b8f7e4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyApplications.vue' /* webpackChunkName: "" */))
const _17728164 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _73d58f12 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyPaymentMethods.vue' /* webpackChunkName: "" */))
const _7543a308 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _f34fd900 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _43df5530 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _c9e44f80 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _c7d8fc8a = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _5bab26cc = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _29bec38e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _354c1a9a = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _cb49cb26 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _e68d631a = () => interopDefault(import('../pages/SeoSitemapCatalog.vue' /* webpackChunkName: "pages/SeoSitemapCatalog" */))
const _6fd96049 = () => interopDefault(import('../pages/SeoSitemapProduct.vue' /* webpackChunkName: "pages/SeoSitemapProduct" */))
const _155895c2 = () => interopDefault(import('../modules/catalog/pages/categorySearch.vue' /* webpackChunkName: "" */))
const _408c7f66 = () => interopDefault(import('../modules/checkout/components/Error.vue' /* webpackChunkName: "" */))
const _9d84b2ce = () => interopDefault(import('../modules/checkout/components/VsfPaymentProvider.vue' /* webpackChunkName: "" */))
const _289d7d97 = () => interopDefault(import('../modules/checkout/pages/Checkout/paypal/Success.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "",
    component: _4a2604bd,
    name: "home"
  }, {
    path: "about-hhip",
    component: _2952519d,
    name: "about-hhip"
  }, {
    path: "careers",
    component: _8e8c4936,
    name: "careers"
  }, {
    path: "cart",
    component: _8aa3f06e,
    name: "cart"
  }, {
    path: "Catalog-Request-Form",
    component: _3e2bbb14,
    name: "Catalog-Request-Form"
  }, {
    path: "contacts",
    component: _2ce7379c,
    name: "contacts"
  }, {
    path: "customer",
    component: _67de5092,
    name: "customer"
  }, {
    path: "error.html",
    component: _dac416cc,
    name: "error_find____hhip"
  }, {
    path: "faq",
    component: _5fcc2d28,
    name: "faq"
  }, {
    path: "privacy-policy",
    component: _0811b395,
    name: "privacy-policy"
  }, {
    path: "terms-conditions",
    component: _19baca3c,
    name: "terms-conditions"
  }, {
    path: "/about-hhip",
    component: _2952519d,
    name: "about-hhip___"
  }, {
    path: "/AboutUs",
    component: _2952519d,
    name: "AboutUs"
  }, {
    path: "/CareerOpportunities",
    component: _8e8c4936,
    name: "CareerOpportunities"
  }, {
    path: "/careers",
    component: _8e8c4936,
    name: "careers___"
  }, {
    path: "/cart",
    component: _8aa3f06e,
    name: "cart"
  }, {
    path: "/cart",
    component: _8aa3f06e,
    name: "cart___"
  }, {
    path: "/Catalog-Request-Form",
    component: _3e2bbb14,
    name: "Catalog-Request-Form___"
  }, {
    path: "/Catalog-Request-Form",
    component: _3e2bbb14,
    name: "Catalog-Request-Form"
  }, {
    path: "/checkout",
    component: _42a3e66f,
    name: "checkout",
    children: [{
      path: "billing",
      component: _5f9990bb,
      name: "billing"
    }, {
      path: "payment",
      component: _036e3a74,
      name: "payment"
    }, {
      path: "shipping",
      component: _595a85c4,
      name: "shipping"
    }, {
      path: "thank-you",
      component: _863eee76,
      name: "thank-you"
    }, {
      path: "user-account",
      component: _156cfaa2,
      name: "user-account"
    }]
  }, {
    path: "/Cms",
    component: _54a05c4a,
    name: "Cms"
  }, {
    path: "/Contact",
    component: _2ce7379c,
    name: "Contact"
  }, {
    path: "/contacts",
    component: _2ce7379c,
    name: "contacts___"
  }, {
    path: "/customer",
    component: _67de5092,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "addresses-details",
      component: _08dd3c00,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "my-applications",
      component: _74b8f7e4,
      meta: {"titleLabel":"My applications"},
      name: "customer-my-applications"
    }, {
      path: "my-newsletter",
      component: _17728164,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-payment-methods",
      component: _73d58f12,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods"
    }, {
      path: "my-profile",
      component: _7543a308,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-reviews",
      component: _f34fd900,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "my-wishlist",
      component: _43df5530,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _c9e44f80,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "/reset-password",
      component: _c7d8fc8a,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }, {
      path: "addresses-details/create",
      component: _5bab26cc,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _29bec38e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history/:orderId",
      component: _354c1a9a,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/customer",
    component: _67de5092,
    name: "customer___"
  }, {
    path: "/Error",
    component: _dac416cc,
    name: "Error"
  }, {
    path: "/error.html",
    component: _dac416cc,
    name: "error_find____hhip___"
  }, {
    path: "/faq",
    component: _5fcc2d28,
    name: "faq___"
  }, {
    path: "/Faq",
    component: _5fcc2d28,
    name: "Faq"
  }, {
    path: "/german",
    component: _4a2604bd,
    name: "home___german"
  }, {
    path: "/germanabout-hhip",
    component: _2952519d,
    name: "about-hhip___german"
  }, {
    path: "/germancareers",
    component: _8e8c4936,
    name: "careers___german"
  }, {
    path: "/germancart",
    component: _8aa3f06e,
    name: "cart___german"
  }, {
    path: "/germanCatalog-Request-Form",
    component: _3e2bbb14,
    name: "Catalog-Request-Form___german"
  }, {
    path: "/germancontacts",
    component: _2ce7379c,
    name: "contacts___german"
  }, {
    path: "/germancustomer",
    component: _67de5092,
    name: "customer___german"
  }, {
    path: "/germanerror.html",
    component: _dac416cc,
    name: "error_find____hhip___german"
  }, {
    path: "/germanfaq",
    component: _5fcc2d28,
    name: "faq___german"
  }, {
    path: "/germanprivacy-policy",
    component: _0811b395,
    name: "privacy-policy___german"
  }, {
    path: "/germanterms-conditions",
    component: _19baca3c,
    name: "terms-conditions___german"
  }, {
    path: "/Home",
    component: _4a2604bd,
    name: "Home"
  }, {
    path: "/Page",
    component: _cb49cb26,
    name: "Page"
  }, {
    path: "/privacy-policy",
    component: _0811b395,
    name: "privacy-policy___"
  }, {
    path: "/Privacy-Policy",
    component: _0811b395,
    name: "Privacy-Policy"
  }, {
    path: "/SeoSitemapCatalog",
    component: _e68d631a,
    name: "SeoSitemapCatalog"
  }, {
    path: "/SeoSitemapProduct",
    component: _6fd96049,
    name: "SeoSitemapProduct"
  }, {
    path: "/terms-conditions",
    component: _19baca3c,
    name: "terms-conditions___"
  }, {
    path: "/Terms-Conditions",
    component: _19baca3c,
    name: "Terms-Conditions"
  }, {
    path: "seo-sitemap/catalog.html",
    component: _e68d631a,
    name: "seo_sitemap"
  }, {
    path: "seo-sitemap/product.html",
    component: _6fd96049,
    name: "seo_sitemap_product"
  }, {
    path: "//AboutUs",
    component: _2952519d,
    name: "AboutUs___"
  }, {
    path: "//CareerOpportunities",
    component: _8e8c4936,
    name: "CareerOpportunities___"
  }, {
    path: "//cart",
    component: _8aa3f06e,
    name: "cart___"
  }, {
    path: "//Catalog-Request-Form",
    component: _3e2bbb14,
    name: "Catalog-Request-Form___"
  }, {
    path: "//checkout",
    component: _42a3e66f,
    name: "checkout___",
    children: [{
      path: "billing",
      component: _5f9990bb,
      name: "billing___"
    }, {
      path: "payment",
      component: _036e3a74,
      name: "payment___"
    }, {
      path: "shipping",
      component: _595a85c4,
      name: "shipping___"
    }, {
      path: "thank-you",
      component: _863eee76,
      name: "thank-you___"
    }, {
      path: "user-account",
      component: _156cfaa2,
      name: "user-account___"
    }]
  }, {
    path: "//Cms",
    component: _54a05c4a,
    name: "Cms___"
  }, {
    path: "//Contact",
    component: _2ce7379c,
    name: "Contact___"
  }, {
    path: "//customer",
    component: _67de5092,
    meta: {"titleLabel":"My Account"},
    name: "customer___",
    children: [{
      path: "addresses-details",
      component: _08dd3c00,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___"
    }, {
      path: "my-applications",
      component: _74b8f7e4,
      meta: {"titleLabel":"My applications"},
      name: "customer-my-applications___"
    }, {
      path: "my-newsletter",
      component: _17728164,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___"
    }, {
      path: "my-payment-methods",
      component: _73d58f12,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods___"
    }, {
      path: "my-profile",
      component: _7543a308,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___"
    }, {
      path: "my-reviews",
      component: _f34fd900,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___"
    }, {
      path: "my-wishlist",
      component: _43df5530,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___"
    }, {
      path: "order-history",
      component: _c9e44f80,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___"
    }, {
      path: "/reset-password",
      component: _c7d8fc8a,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }, {
      path: "addresses-details/create",
      component: _5bab26cc,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___"
    }, {
      path: "//reset-password",
      component: _c7d8fc8a,
      alias: "/customer/account/createPassword",
      name: "reset-password___"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _29bec38e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___"
    }, {
      path: "order-history/:orderId",
      component: _354c1a9a,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___"
    }]
  }, {
    path: "//Error",
    component: _dac416cc,
    name: "Error___"
  }, {
    path: "//Faq",
    component: _5fcc2d28,
    name: "Faq___"
  }, {
    path: "//Home",
    component: _4a2604bd,
    name: "Home___"
  }, {
    path: "//Page",
    component: _cb49cb26,
    name: "Page___"
  }, {
    path: "//Privacy-Policy",
    component: _0811b395,
    name: "Privacy-Policy___"
  }, {
    path: "//SeoSitemapCatalog",
    component: _e68d631a,
    name: "SeoSitemapCatalog___"
  }, {
    path: "//SeoSitemapProduct",
    component: _6fd96049,
    name: "SeoSitemapProduct___"
  }, {
    path: "//Terms-Conditions",
    component: _19baca3c,
    name: "Terms-Conditions___"
  }, {
    path: "/catalogsearch/result",
    component: _155895c2,
    name: "result search___"
  }, {
    path: "/german/AboutUs",
    component: _2952519d,
    name: "AboutUs___german"
  }, {
    path: "/german/CareerOpportunities",
    component: _8e8c4936,
    name: "CareerOpportunities___german"
  }, {
    path: "/german/cart",
    component: _8aa3f06e,
    name: "cart___german"
  }, {
    path: "/german/Catalog-Request-Form",
    component: _3e2bbb14,
    name: "Catalog-Request-Form___german"
  }, {
    path: "/german/checkout",
    component: _42a3e66f,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _5f9990bb,
      name: "billing___german"
    }, {
      path: "payment",
      component: _036e3a74,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _595a85c4,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _863eee76,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _156cfaa2,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _54a05c4a,
    name: "Cms___german"
  }, {
    path: "/german/Contact",
    component: _2ce7379c,
    name: "Contact___german"
  }, {
    path: "/german/customer",
    component: _67de5092,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _08dd3c00,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-applications",
      component: _74b8f7e4,
      meta: {"titleLabel":"My applications"},
      name: "customer-my-applications___german"
    }, {
      path: "my-newsletter",
      component: _17728164,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-payment-methods",
      component: _73d58f12,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods___german"
    }, {
      path: "my-profile",
      component: _7543a308,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _f34fd900,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _43df5530,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _c9e44f80,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _5bab26cc,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _c7d8fc8a,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _29bec38e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _354c1a9a,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Error",
    component: _dac416cc,
    name: "Error___german"
  }, {
    path: "/german/Faq",
    component: _5fcc2d28,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _4a2604bd,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _cb49cb26,
    name: "Page___german"
  }, {
    path: "/german/Privacy-Policy",
    component: _0811b395,
    name: "Privacy-Policy___german"
  }, {
    path: "/german/SeoSitemapCatalog",
    component: _e68d631a,
    name: "SeoSitemapCatalog___german"
  }, {
    path: "/german/SeoSitemapProduct",
    component: _6fd96049,
    name: "SeoSitemapProduct___german"
  }, {
    path: "/german/Terms-Conditions",
    component: _19baca3c,
    name: "Terms-Conditions___german"
  }, {
    path: "/germancatalogsearch/result",
    component: _155895c2,
    name: "result search___german"
  }, {
    path: "/germanseo-sitemap/catalog.html",
    component: _e68d631a,
    name: "seo_sitemap___german"
  }, {
    path: "/germanseo-sitemap/product.html",
    component: _6fd96049,
    name: "seo_sitemap_product___german"
  }, {
    path: "/seo-sitemap/catalog.html",
    component: _e68d631a,
    name: "seo_sitemap___"
  }, {
    path: "/seo-sitemap/product.html",
    component: _6fd96049,
    name: "seo_sitemap_product___"
  }, {
    path: "catalogsearch/result/",
    component: _155895c2,
    name: "result search"
  }, {
    path: "checkout/amazon/cancel",
    component: _408c7f66,
    name: "amazon_cancel____hhip"
  }, {
    path: "checkout/amazon/success",
    component: _9d84b2ce,
    name: "amazon_success___hhip"
  }, {
    path: "checkout/paypal/cancel",
    component: _408c7f66,
    name: "paypal_cancel____hhip"
  }, {
    path: "checkout/paypal/success",
    component: _289d7d97,
    name: "paypal_success___hhip"
  }, {
    path: "checkout/stripe/error.html",
    component: _408c7f66,
    name: "payment_error___hhip"
  }, {
    path: "customer/addresses-details/create",
    component: _5bab26cc,
    name: "addresses-details/create"
  }, {
    path: "/checkout/amazon/cancel",
    component: _408c7f66,
    name: "amazon_cancel____hhip___"
  }, {
    path: "/checkout/amazon/success",
    component: _9d84b2ce,
    name: "amazon_success___hhip___"
  }, {
    path: "/checkout/paypal/cancel",
    component: _408c7f66,
    name: "paypal_cancel____hhip___"
  }, {
    path: "/checkout/paypal/success",
    component: _289d7d97,
    name: "paypal_success___hhip___"
  }, {
    path: "/checkout/stripe/error.html",
    component: _408c7f66,
    name: "payment_error___hhip___"
  }, {
    path: "/customer/addresses-details/create",
    component: _5bab26cc,
    name: "addresses-details/create___"
  }, {
    path: "/germancheckout/amazon/cancel",
    component: _408c7f66,
    name: "amazon_cancel____hhip___german"
  }, {
    path: "/germancheckout/amazon/success",
    component: _9d84b2ce,
    name: "amazon_success___hhip___german"
  }, {
    path: "/germancheckout/paypal/cancel",
    component: _408c7f66,
    name: "paypal_cancel____hhip___german"
  }, {
    path: "/germancheckout/paypal/success",
    component: _289d7d97,
    name: "paypal_success___hhip___german"
  }, {
    path: "/germancheckout/stripe/error.html",
    component: _408c7f66,
    name: "payment_error___hhip___german"
  }, {
    path: "/germancustomer/addresses-details/create",
    component: _5bab26cc,
    name: "addresses-details/create___german"
  }, {
    path: "/",
    component: _4a2604bd,
    name: "home___"
  }, {
    path: "/customer/addresses-details/edit/:slug+",
    component: _29bec38e,
    name: "addresses-details/edit___"
  }, {
    path: "/germancustomer/addresses-details/edit/:slug+",
    component: _29bec38e,
    name: "addresses-details/edit___german"
  }, {
    path: "/checkout/thank-you/:slug+",
    component: _863eee76,
    name: "checkout/thank-you___"
  }, {
    path: "/germancheckout/thank-you/:slug+",
    component: _863eee76,
    name: "checkout/thank-you___german"
  }, {
    path: "customer/addresses-details/edit/:slug+",
    component: _29bec38e,
    name: "addresses-details/edit"
  }, {
    path: "checkout/thank-you/:slug+",
    component: _863eee76,
    name: "checkout/thank-you"
  }, {
    path: "/:slug+",
    component: _cb49cb26,
    name: "page___"
  }, {
    path: "/german:slug+",
    component: _cb49cb26,
    name: "page___german"
  }, {
    path: ":slug+",
    component: _cb49cb26,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
